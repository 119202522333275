<template>
  <div class="AutomaticMeterWater" @click="treePanelVisible = false">
    <div class="filter-panel">
      <div
          style="
          vertical-align: middle;
          margin-bottom: 15px;
          display: inline-block;
        "
      >
        <input
            v-model="conditions.selectPut"
            class="inputSelect"
            placeholder="搜索水表名称/编号/关联房间/表箱号/当前业主/租客/备注"
            type="text"
        />
      </div>
      <CSSelect>
        <select v-model="conditions.checkedGroupId">
          <option value="">全部表组</option>
          <option
              v-for="identity in groupList"
              :key="identity.id"
              :value="identity.id"
          >
            {{ identity.name }}
          </option>
        </select>
      </CSSelect>
      <CSSelect>
        <select v-model="conditions.balanced">
          <!--                    <option value="">剩余预存金额</option>-->
          <option
              v-for="identity in groupListMony"
              :key="identity.id"
              :value="identity.id"
          >
            {{ identity.name }}
          </option>
        </select>
      </CSSelect>
      <button class="btn btn-primary btn-sm" type="button" @click="query()">
        查询
      </button>
    </div>
    <!-- 表单 -->
    <div class="result-panel">
      <CSTable :thead-top="filterHeight">
        <template v-slot:header>
          <div class="table-header-panel text-left">
            <div style="margin-right: 30px">
              水表总计:{{ total }}个
            </div>
            <div>
              剩余预存金额总计:{{ balanceSum }}元
            </div>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>水表名称/编号</th>
            <th>位置</th>
            <th>关联房间</th>
            <th>表箱号</th>
            <th>表组</th>
            <th>倍率规则</th>
            <th>倍率</th>
            <th>最新抄表时间</th>
            <th>最新读数</th>
            <th>水价（元/吨）</th>
            <th>剩余预存金额（元）</th>
            <th>当前业主/租客</th>
            <th>备注</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template>
            <tr v-for="item in waterReadingList" :key="item.id">
              <td>
                <span>{{ item.imei }}</span>
              </td>
              <td>{{ item.locationName || '-' }}</td>
              <td> <span>{{
                  item.correlationRoom ? item.correlationRoom : "-"
                }}</span>
              </td>
              <td>
                <span>{{ item.meterBoxNo || '-' }}</span>
              </td>
              <td>
                <span>{{ item.groupNames ? item.groupNames.join("、") : "-" }}</span>
              </td>
              <td>
                <span>{{ item.rationRule ? '用量=读数/倍率' : '用量=读数*倍率' }}</span>
              </td>
              <td>
                <span>{{ item.rate || '-' }}</span>
              </td>
              <td style="min-width: 125px;">
                <span>{{ item.updateTime || '-' }}</span>
              </td>
              <td>
                <span>{{ item.ton }}</span>
              </td>
              <td>
                <span>{{ $vc.summationBig(item.basisPrice, item.additionPrice) || '-' }}</span>
              </td>
              <td style="min-width: 140px">
                <span>{{ item.balance || '0' }}</span>
              </td>
              <td>
                              <span
                                  v-if="item.companyName"
                                  class="btn-active"
                                  @click="getInformation(item)"
                              >{{ item.companyName }}</span
                              >
                <span v-else> - </span>
              </td>
              <td>
                                <span v-if="item.commit" class="allow-click" @click="lookComment(item.commit)">
                                    查看
                                </span>
                <span v-else>
                                    -
                                </span>
              </td>
              <td>
                <div class="btn-group">
                  <button
                      aria-expanded="false"
                      aria-haspopup="true"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      type="button"
                  >
                    操作
                  </button>
                  <ul class="dropdown-menu" style="width: 112px">
                    <li>
                      <a @click="enableResidueBalance(item,1)">重算剩余预存</a>
                    </li>
                    <li>
                      <a @click="openTableSetUp(item,$event,1)">水表设置</a>
                    </li>
                    <li>
                      <a @click="enableEditMeterCommit(item,1)">备注</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </CSTable>
      <Pagination componentName="Pagination" name="pagination"></Pagination>

    </div>


    <!--修改水表剩余预存-->
    <CSDialog
        :dialogVisible="editResidueBalanceFlag"
        :dialogWidth="520"
        dialogConfirmBtnText="确认更新"
        dialogTitle="重算剩余预存金额"
        @onClose="clearBalance"
        @onConfirm="editWaterBalance"
    >
      <div
          slot="dialog-content" class="dialog-content"
          style="padding: 30px 30px 20px; font-size: 24px;"
      >
        <span style="margin-right: 30px">选择日期</span>
        <CSSelect class="wData" i-width="40px">
          <el-date-picker
              v-model="queryBalanceParams.startTime"
              :editable="true"
              format="yyyy-MM-dd"
              placeholder="请选择"
              prefix-icon="el-icon-time"
              type="date"
              value-format="yyyy-MM-dd"
          ></el-date-picker>
        </CSSelect>
        <button
            class="btn btn-primary btn-sm"
            style="margin-left: 20px"
            type="button"
            @click="queryWaterBalance"
        >
          计算
        </button>
      </div>
      <div
          slot="dialog-content" class="dialog-content"
          style="padding: 10px 30px 10px; font-size: 24px;"
      >
        <span v-if="balanced == null ">剩余预存金额(元)&nbsp;&nbsp;暂未计算</span>
        <span v-else>剩余预存金额(元)&nbsp;&nbsp;{{ balanced }}</span>
      </div>
      <div
          slot="dialog-content" class="dialog-content"
          style="padding: 10px 30px 20px; font-size: 24px;"
      >
        <svg
            aria-hidden="true"
            class="icon"
            style="font-size: 20px; margin-right: 10px"
        >
          <use xlink:href="#icon-menua-zu92"></use>
        </svg>

        <span style="font-size: 20px;color: #999999"
        >只计算所选日期当天及之后产生的金额总计。</span
        >
      </div>
    </CSDialog>
    <!--修改备注-->
    <CSDialog
        :dialog-visible="editCommitFlag"
        :dialog-width="530"
        :dialogTitle="`备注`"
        @onClose="editCommitFlag = false"
        @onConfirm="editMeterCommit"
    >
      <template v-slot:dialog-content>
        <div class="preview-tenant">
          <div class="field">
            <span class="field-label">备注</span>
            <textarea
                v-model="editCommitInfo.commit"
                maxlength="50"
                placeholder="限50个字"
                style="
                                resize:none;
                                padding:10px;
                                width:370px;
                                height:200px;
                                overflow-y:auto;
                                background-color:#F0F0F0;
                                border-radius:4px;
                                border:0px;
                            "
            ></textarea>
          </div>
        </div>
      </template>
    </CSDialog>

    <!--水表设置-->
    <CSDialog
        :dialog-visible="setUpFlag"
        :dialog-width="730"
        :dialogTitle="`水表设置`"
        @onClose="setUpFlag = false"
        @onConfirm="editSetUpFlag"
    >
      <template v-slot:dialog-content>
        <div class="dialog-form" @click="treePanelVisibleD">
          <div class="dialog-form-item" style="display: flex;align-items: center;">
            <div class="dialog-form-item-label" style="vertical-align: top;width: auto;">关联位置</div>
            <div style="width:400px">
              <ChooseLocation :defaultLocationName="editMeterLocationInfo.locationName"
                              @changeLocation="changeLocation"/>
            </div>
          </div>
          <div class="dialog-form-item" style="display: flex;align-items: center;">
            <div class="dialog-form-item-label" style="width: auto;padding-left: 30px">表箱号</div>
            <div>
              <input v-model="editMeterBoxNoInfo.meterBoxNo" class="input" placeholder="限20个字"
                     style="width: 400px"
                     type="text">
            </div>
          </div>
          <div class="dialog-form-item" style="display: flex;align-items: center;">
            <div class="dialog-form-item-label" style="vertical-align: top;width: auto;">表组</div>
            <div style="width:400px;position: relative">
              <CSSelect i-width="40px" style="height: 40px;width:400px">
                <!--                                <input v-model="groupNumber" type="text" style="width: 100%;border: none" -->
                <!--                                       placeholder="" @click="treePanelVisibleBtn">-->
                <div style="width: 100%;height: 100%;box-sizing: border-box;padding-left: 10px"
                     @click="treePanelVisibleBtn">
                  已选中{{ groupNumber }}个
                </div>
              </CSSelect>
              <TreePanel v-show="treePanelVisible">
                <CSTree
                    :tree="groupList"
                    @change="changeStaff"
                ></CSTree>
              </TreePanel>
            </div>
          </div>
          <div class="dialog-form-item" style="display: flex;align-items: center;">
            <div class="dialog-form-item-label"
                 style="vertical-align: top;width: auto;height:auto;padding-bottom: 20px">倍率规则
            </div>
            <div style="width:500px;position: relative">
              <CSRadio v-model="rationRule" :items="{0:'用量=读数*倍率',1:'用量=读数/倍率'}"></CSRadio>
              <div style="font-size: 18px;color: #999">
                <svg aria-hidden="true" class="icon">
                  <use xlink:href="#icon-menua-zu92"></use>
                </svg>
                <span>水用量单位（吨）。</span>
              </div>

            </div>
          </div>
          <div class="dialog-form-item" style="display: flex;align-items: center;">
            <div class="dialog-form-item-label" style="vertical-align: top;width: auto;">计价规则</div>
            <div style="width:500px;position: relative">
              <CSRadio v-model="pricingRules" :items="{0:'统一计价',1:'单独计价'}"></CSRadio>
            </div>
          </div>
          <div class="dialog-form-item" style="display: flex;align-items: center;">
            <div class="dialog-form-item-label" style="width: auto;padding-left: 15px">基础水价(元/吨)</div>
            <div>
              <input v-if="pricingRules == 1" v-model="basisPrice" class="input"
                     placeholder="请输入"
                     style="width: 150px" type="Number"
                     @input="numberCheck(basisPrice,$event)">
              <span v-else>{{ setWater.waterPrice }}</span>
            </div>
          </div>
          <div class="dialog-form-item" style="display: flex;align-items: center;">
            <div class="dialog-form-item-label" style="width: auto;padding-left: 15px">附加水价(元/吨)</div>
            <div>
              <input v-if="pricingRules == 1" v-model="additionPrice" class="input"
                     placeholder="请输入"
                     style="width: 150px" type="Number" @input="numberCheckd(additionPrice,$event)">
              <span v-else>{{ setWater.waterOtherPrice }}</span>
            </div>
          </div>
          <div class="dialog-form-item" style="display: flex;align-items: center;">
            <div class="dialog-form-item-label" style="width: auto;padding-left: 10px">水价总计(元/吨)</div>
            <div v-if="pricingRules == 1">
              {{ totald }}
            </div>
            <span v-else>{{ parseFloat(setWater.waterOtherPrice) + parseFloat(setWater.waterPrice) }}</span>
          </div>
        </div>
      </template>
    </CSDialog>
  </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import CSTable from "@/components/common/CSTable";
import CSRadio from "@/components/common/CSRadio";
import TreePanel from "@/components/common/TreePanel";
import {
  canPayUrl,
  editMeterBoxNoUrl,
  editMeterCommitUrl,
  editMeterLocationUrl,
  editWaterBalanceUrl,
  hydropowerContractInformationUrl,
  hydropowerGetInfoUrl,
  modifyWaterUrl,
  queryMeterGroupUrl,
  queryWaterBalanceUrl,
  waterReadingUrl
} from "@/requestUrl";
import Pagination from "@/components/Pagination.vue";
import CSDialog from "@/components/common/CSDialog";
import CSTree from "@/components/common/CSTree";
import ChooseLocation from "@/components/ChooseLocation";

export default {
  name: "AutomaticMeterWater",
  created() {
    this.queryGroupList();
    this.getHydropowerInfo();
  },
  computed: {
    totald() {
      return (+this.additionPrice + (+this.basisPrice)).toFixed(2) || 0;
    }
  },
  data() {
    return {
      setUpMoney: {
        electricityId: "",
        electricityOtherPrice: "",
        electricityPrice: "",
        sendDate: ""
      },
      setWater: {
        waterId: "",
        waterOtherPrice: "",
        waterPrice: "",
        sendDateWater: ""
      },
      id: "",
      additionPrice: 0,//附加水价
      basisPrice: 0,//基础水价
      pricingRules: 0,//计价规则
      rationRule: 0,//倍率规则
      setUpFlag: false,
      balanced: 0,
      conditions: {
        selectPut: '',
        balanced: 0,
        checkedGroupId: "",

      },
      editMeterBoxNoFlag: false,
      editResidueBalanceFlag: false,
      queryBalanceParams: {
        startTime: dayjs().startOf('month').format('YYYY-MM-DD'),
        meterType: "",
        meterId: ""
      },
      editMeterBoxNoInfo: {
        id: "",
        type: "",
        meterBoxNo: ""
      },
      editMeterLocationInfo: {
        id: "",
        type: "",
        locationId: "",
        locationName: ""
      },
      hydropowerInfoList: [],
      total: 0,
      waterReadingList: [],
      balanceSum: 0,
      groupList: [],//全部表组
      filterHeight: 0,
      groupListMony: [
        {
          id: 0,
          name: '剩余预存金额不限'
        }, {
          id: 1,
          name: '小于50'
        }, {
          id: 2,
          name: '小于150'
        }, {
          id: 3,
          name: '小于350'
        }, {
          id: 4,
          name: '小于700'
        }, {
          id: 5,
          name: '大于等于700'
        }
      ],
      openType: "",
      meterId: "",
      checkedDepartment: [],
      groupNumber: "",
      tableGroupDiog: false,
      treePanelVisible: false,
      editCommitFlag: false,
      editCommitInfo: {
        id: "",
        type: "",
        commit: ""
      },
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  mounted() {
    window.addEventListener("keydown", this.queryEnd);
    this.query();
    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.pageParams.pageNo = pageNo;
      this.query(pageNo);
    });
  },
  deactivated() {
    window.removeEventListener("keydown", this.queryEnd);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.queryEnd);
  },
  methods: {
    inputCh() {

    },
    numberCheck(item, event) {
      var value = item;
      var zeroFirst = value.indexOf(0);
      var dot = value.indexOf('.');
      var vlen = value.length
      if (value.indexOf('.') == 0) {
        this.$message.error("请输入合法的数字!");
        item = 0;

        this.basisPrice = 0;
      }
      if (zeroFirst === 0) {//首位为0,第二位则必须为.
        if (vlen >= 3 && value.indexOf('.') == 1) {
          if (value.length > 4) {
            item = value.substr(0, vlen - 1);
            this.basisPrice = value.substr(0, vlen - 1);
            this.$message.error("最多两位小数!");
          } else {
            if (isNaN(Number(value[vlen - 1]))) {
              item = value.substr(0, vlen - 1);
              this.basisPrice = value.substr(0, vlen - 1);
              this.$message.error("请输入合法的数字!");
            }
          }
        } else if (vlen == 2 && value.indexOf('.') != 1) {
          this.$message.error("请输入小数点!");
          item = 0;
          this.basisPrice = 0;
        }
      } else {
        if (dot > -1 && dot + 1 != vlen) {//已输入小数点
          if (isNaN(Number(value[vlen - 1]))) {//小数点后只能输入0-9
            item = value.substr(0, vlen - 1);
            this.basisPrice = value.substr(0, vlen - 1);
            this.$message.error("请输入合法的数字0-9!");
          } else if (vlen - dot > 2 + 1) {
            item = value.substr(0, vlen - 1);
            this.basisPrice = value.substr(0, vlen - 1);
            this.$message.error("最多两位小数!");
          }
        } else if (dot == -1) {

        }
      }
    },
    numberCheckd(item, event) {
      var value = item;
      var zeroFirst = value.indexOf(0);
      var dot = value.indexOf('.');
      var vlen = value.length
      if (value.indexOf('.') == 0) {
        this.$message.error("请输入合法的数字!");
        item = 0;

        this.additionPrice = 0;
      }
      if (zeroFirst === 0) {//首位为0,第二位则必须为.
        if (vlen >= 3 && value.indexOf('.') == 1) {
          if (value.length > 4) {
            item = value.substr(0, vlen - 1);
            this.additionPrice = value.substr(0, vlen - 1);
            this.$message.error("最多两位小数!");
          } else {
            if (isNaN(Number(value[vlen - 1]))) {
              item = value.substr(0, vlen - 1);
              this.additionPrice = value.substr(0, vlen - 1);
              this.$message.error("请输入合法的数字!");
            }
          }
        } else if (vlen == 2 && value.indexOf('.') != 1) {
          this.$message.error("请输入小数点!");
          item = 0;
          this.additionPrice = 0;
        }
      } else {
        if (dot > -1 && dot + 1 != vlen) {//已输入小数点
          if (isNaN(Number(value[vlen - 1]))) {//小数点后只能输入0-9
            item = value.substr(0, vlen - 1);
            this.additionPrice = value.substr(0, vlen - 1);
            this.$message.error("请输入合法的数字0-9!");
          } else if (vlen - dot > 2 + 1) {
            item = value.substr(0, vlen - 1);
            this.additionPrice = value.substr(0, vlen - 1);
            this.$message.error("最多两位小数!");
          }
        } else if (dot == -1) {

        }
      }
    },
    //回车查询
    queryEnd(e) {
      if (e.keyCode == 13) {
        this.query();
      }
    },
    //打开设置
    openTableSetUp(item, event, type) {
      this.setUpFlag = true;
      //位置
      this.enableEditMeterLocation(item, type);
      //表箱号
      this.enableEditMeterBoxNo(item, type);
      //表组
      this.openTableGroup(item, event, type);
      //倍率
      this.rationRule = item.rationRule;
      //计价规则
      this.pricingRules = item.priceRule;
      this.additionPrice = item.additionPrice;//附加水价
      this.basisPrice = item.basisPrice;//基础水价
      console.log('id');
      console.log(item);
      this.id = item.meterId;
    },
    //水表设置
    editSetUpFlag() {
      let groupIds = this.checkedDepartment.map(item => {
        return item.id
      });
      this.$fly.post(modifyWaterUrl, {
        "id": this.id, // ID  必填
        "regionCode": this.$vc.getCurrentRegion().code, //园区code  必填
        "meterBoxNo": this.editMeterBoxNoInfo.meterBoxNo, //表箱号 必填
        "locationId": this.editMeterLocationInfo.locationId, //位置ID 必填
        locationName: this.editMeterLocationInfo.locationName, // 具体位置 必填
        "groupIds": groupIds, // 表组 必填
        "ratioRule": this.rationRule, //倍率规则 0-用量=读数*倍率  1-用量=读数/倍率 必填
        "priceRule": this.pricingRules, // 计价规则 0-统一计价  1-单独计价 必填
        "basisPrice": this.basisPrice, // 基础水价 单独计价时必填
        "additionPrice": this.additionPrice // 附加水价 单独计价时必填
      }).then(res => {
        if (res.code != 0) {
          return
        }
        this.setUpFlag = false;
        this.$vc.toast("保存成功");
        this.query();
      })
    },
    editMeterCommit() {
      this.$fly.post(editMeterCommitUrl, {
        id: this.editCommitInfo.id,
        type: this.editCommitInfo.type,
        commit: this.editCommitInfo.commit,
      })
          .then(res => {
            if (res.code != 0) {
              return;
            }
            this.editCommitFlag = false;
            this.$vc.toast("保存成功");
            this.query();
          })
    },
    enableEditMeterCommit(item, type) {
      this.editCommitInfo.id = item.meterId;
      this.editCommitInfo.type = type;
      this.editCommitInfo.commit = item.commit;
      this.editCommitFlag = true;

    },
    //保存表组
    editTableGroup() {
      let groupIds = this.checkedDepartment.map(item => {
        return item.id
      })
      this.$fly.post(canPayUrl, {
        regionCode: this.$vc.getCurrentRegion().code, //区域编码
        "type": this.openType,
        groupIds,  //表组id
        "meterId": this.meterId
      }).then(res => {
        if (res.code != 0) {
          return
        }
        this.$vc.toast('关联成功');
        this.query();
        this.tableGroupDiog = false;

      })

    },
    treePanelVisibleBtn(event) {
      event.cancelBubble = true;
      this.treePanelVisible = true;
    },
    treePanelVisibleD(event) {
      this.treePanelVisible = false;
    },
    //选中状态发生变化
    changeStaff(obj) {
      const item = obj.source[obj.key];
      //const checked = item.checked;
      item.checked = !item.checked;

      this.checkedDepartment = this.groupList.filter((item) => {
        return item.checked;
      });
      this.groupNumber = this.checkedDepartment.length;
      console.log(this.checkedDepartment);
    },
    //打开关联表组
    openTableGroup(it, event, type) {
      this.openType = type;

      this.meterId = it.meterId;
      this.groupList = this.groupList.map(item => {
        let checked = it.groupNames && it.groupNames.includes(item.name);
        return {
          ...item,
          checked
        }
      })
      this.checkedDepartment = this.groupList.filter((item) => {
        return item.checked;
      });
      this.groupNumber = this.checkedDepartment.length;
    },
    //获取水电表基础信息
    getHydropowerInfo() {
      this.$fly
          .get(hydropowerGetInfoUrl, {
            regionCode: this.$vc.getCurrentRegion().code, //区域编码
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            console.log('基础信息');
            console.log(res.data);
            this.setUpMoney = {
              electricityId: res.data.electricityId,
              electricityOtherPrice: res.data.electricityOtherPrice,
              electricityPrice: res.data.electricityPrice,
              sendDate: res.data.electricitySendDate
            };
            this.setWater = {
              waterId: res.data.waterId,
              waterOtherPrice: res.data.waterOtherPrice,
              waterPrice: res.data.waterPrice,
              sendDateWater: res.data.waterSendDate
            }

          });
    },
    editMeterBoxNo() {
      this.$fly.post(editMeterBoxNoUrl, {
        id: this.editMeterBoxNoInfo.id,
        type: this.editMeterBoxNoInfo.type,
        meterBoxNo: this.editMeterBoxNoInfo.meterBoxNo,
      })
          .then(res => {
            if (res.code != 0) {
              return;
            }
            this.editMeterBoxNoFlag = false;
            this.$vc.toast("保存成功");
            this.query();
          })
    },
    //修改备注接口
    enableEditMeterBoxNo(item, type) {
      this.editMeterBoxNoInfo.id = item.meterId;
      this.editMeterBoxNoInfo.type = type;
      this.editMeterBoxNoInfo.meterBoxNo = item.meterBoxNo;

    },
    enableEditMeterLocation(item, type) {
      this.editMeterLocationInfo.id = item.meterId;
      this.editMeterLocationInfo.type = type;
      this.editMeterLocationInfo.locationId = item.locationId;
      this.editMeterLocationInfo.locationName = item.locationName;
    },
    //计算使用金额
    queryWaterBalance() {
      this.$fly.post(queryWaterBalanceUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
        meterType: this.queryBalanceParams.meterType,
        meterId: this.queryBalanceParams.meterId,
        startTime: this.queryBalanceParams.startTime
      })
          .then(res => {
            if (res.code != 0) {
              return;
            }
            this.balanced = res.data;
          })
    },
    changeLocation(location) {
      this.editMeterLocationInfo.locationId = location.id;
      let buildingName = `${location.buildingName}`
      let floor = `${location.floor}`
      let locationInfo = '';
      if (!(buildingName === '') && !(buildingName === 'undefined' && !(buildingName === undefined))) {
        locationInfo += buildingName;
      }
      if (!(floor === '') && !(floor === undefined) && !(floor === 'undefined')) {
        locationInfo += floor + '层';
      }
      locationInfo += `${location.specificLocation}`;
      this.editMeterLocationInfo.locationName = locationInfo;
      console.log(this.editMeterLocationInfo.locationName);
    },
    editWaterBalance() {
      this.$fly.post(editWaterBalanceUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
        meterType: this.queryBalanceParams.meterType,
        meterId: this.queryBalanceParams.meterId,
        startTime: this.queryBalanceParams.startTime,
        balance: this.balanced
      })
          .then(res => {
            if (res.code != 0) {
              return;
            }
            this.editResidueBalanceFlag = false
            this.$vc.toast("更新成功，请查看当前剩余预存金额");
            this.query();
          })
    },
    //关联位置
    editMeterLocation() {
      this.$fly.post(editMeterLocationUrl, {
        id: this.editMeterLocationInfo.id,
        type: this.editMeterLocationInfo.type,
        locationId: this.editMeterLocationInfo.locationId,
        locationName: this.editMeterLocationInfo.locationName
      })
          .then(res => {
            if (res.code != 0) {
              return;
            }
            this.editMeterLocationFlag = false;
            this.$vc.toast("保存成功");
            this.query();
          })
    },
    clearBalance() {
      this.balanced = null
      this.editResidueBalanceFlag = false;
    },
    //重算预存
    enableResidueBalance(item, type) {
      this.balanced = null
      this.queryBalanceParams.startTime = dayjs().startOf('month').format('YYYY-MM-DD')
      this.queryBalanceParams.meterType = type;
      this.queryBalanceParams.meterId = item.meterId
      this.editResidueBalanceFlag = true;
    },
    //查询
    query(pageNo = this.pageParams.pageNo, pageSize = 10) {
      this.$fly
          .post(waterReadingUrl, {
            search: this.conditions.selectPut, //搜索框内容
            pageSize: pageSize, //页面大小
            pageNo: pageNo, //页码
            regionCode: this.$vc.getCurrentRegion().code, //区域编码
            groupId: this.conditions.checkedGroupId,
            balance: this.conditions.balanced
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            if (pageNo == 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                currentPage: pageNo,
                pageSize: pageSize,
              });
            }
            this.total = res.data.total;

            this.balanceSum = res.data.balanceSum;
            this.waterReadingList = res.data.datas;
          });
    },
    //查询表组
    queryGroupList() {
      this.$fly.post(queryMeterGroupUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
      })
          .then(res => {
            if (res.code != 0) {
              return;
            }
            this.groupList = res.data.datas;
          })
    },
    //获取租客信息
    async getInformation(item) {
      this.hydropowerInfoList = await this.$fly
          .post(hydropowerContractInformationUrl, {
            cid: item.cid,
            lesseeId: item.lesseeId
          })
          .then((res) => {
            return res?.data;
          });
      this.$CSDialog.alert({
        width: "700px",
        title: "当前业主/租客",
        messageHtml: `
                    <div class="comment-panel" style="--item-label: 160px;">
                        <div class="item right">
                            <div class="item-label">企业/个人名称</div>
                            <div>${this.hydropowerInfoList.companyName}</div>
                        </div>
                        <div class="item right">
                            <div class="item-label">合同时间</div>
                            <div>${
            this.hydropowerInfoList.startDate ? this.hydropowerInfoList.startDate : "-"}
                              至
                              ${this.hydropowerInfoList.endDate ? this.hydropowerInfoList.endDate : "-"}
                            </div>
                        </div>
                        <div class="item right">
                            <div class="item-label">对接人</div>
                            <div>${this.hydropowerInfoList.deputyName}</div>
                        </div>
                        <div class="item right">
                            <div class="item-label">对接人手机号</div>
                            <div>${this.hydropowerInfoList.deputyPhone}</div>
                        </div>
                    </div>
                `,
      });
    },
    lookComment: function lookComment(comment) {
      this.$CSDialog.alert({
        title: '查看备注',
        message: comment
      });
    },
  },
  components: {
    CSSelect,
    CSTable,
    Pagination,
    CSDialog,
    ChooseLocation,
    TreePanel,
    CSTree,
    CSRadio
  }
}
</script>

<style lang="stylus" scoped>
.dialog-form-item-label
  min-width 180px
  text-align right

.inputSelect {
  color: #000;
  width: 240px;
  height: 30px;
  padding-left: 10px;
  border-radius: 4px;
  border: 1px solid #979797;
  margin-right: 20px;
}

.text-left {
  display: flex;
}

.preview-tenant
  color #000
  padding 30px
  font-size 24px
  overflow-y auto

  .field
    &:not(:last-of-type)
      margin-bottom 20px

    &-label
      width 60px
      display inline-block
      margin-right 40px
      text-align right
      vertical-align top

    &-content
      display inline-block
      max-width calc(100% - 240px)

.btn-active {
  color: #1dafff;
  text-decoration: underline;
  cursor: pointer;
}

.dialog-form
  padding 27px 30px
  font-size 24px
  color #000

  &-item
    &:not(:last-of-type)
      margin-bottom 23px

    &-label
      width 156px
      height 33px
      text-align right
      margin-right 40px
      display inline-block
      vertical-align middle
      line-height 33px

    & > div
      display inline-block
      vertical-align middle

      input
        &::placeholder
          color #999
        width 140px
        height 40px
        border-radius 4px
        padding 0 5px
        border 1px solid #979797
        vertical-align middle
        padding-left 10px

</style>
